<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

function copyUrl() {
  navigator.clipboard.writeText(props.url)
}
</script>

<template>
  <div class="flex w-full items-center justify-between rounded-md bg-gray-50">
    <input
      class="h-12 grow rounded-md bg-gray-50 !pl-2 !pr-2"
      type="text"
      :value="url"
    />
    <div
      class="text-blue-dark mr-1 flex h-10 w-[122px] flex-shrink-0 flex-grow-0 cursor-pointer items-center justify-center whitespace-nowrap rounded-3xl border-2 border-solid border-gray-100 bg-white text-center text-sm font-medium"
      @click="copyUrl"
    >
      Kopieer link
    </div>
  </div>
</template>
